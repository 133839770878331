import { CiudadDTO, SolicitudCredito } from "./Solicitud-Credito";

export class MenuDetalleSolicitud {
  idMenu: number;
  nombre: string;
  ruta: string;
  revisado: boolean;
}

export class RequestLogin {
  tipoDocumento: number;
  tipoPerfil: number;
  numDocumento: string;
  password: string;
  sNavegador: string;
  iTipoCanal: number;
}
export class TipoDireccion {
  ICodigo: any;
  SDescripcion: any;
  CDescripcion: any;
}

export class CompDireccion {
  ICodigo: any;
  SDescripcion: any;
  CDescripcion: any;
}
export class frmDireccion {

  public TipoPrimera: any;
  public Nprincipal: any;
  public SufijoPrimero: any;
  public NSecundario: any;
  public SufijoSegundo: any;
  public NComp: any;
  public ComplementoPrimero: any;
  public NumeroPrimero: any;
  public complementoSegundo: any;
  public NumeroSegundo: any

}
export class ResponseTipoContrato {
  BRta: boolean;
  SMsjConsulta: string;
  listTipoContr: ArrayOfTipoContratoDTO;
}

export class ArrayOfTipoContratoDTO {
  TipoContratoDTO: TipoContratoDTO[];
}

export class TipoContratoDTO {
  ICodigo: number;
  SDescripcion: string;
}

export class ResponseTiposIdent {
  BRta: boolean;
  SMsjConsulta: string;
  LstTiposIdent: ArrayOfTipoIdentificacionDTO;
}

export class ArrayOfTipoIdentificacionDTO {
  tipoIdentificacionDTO: TipoIdentificacionDTO[];
}

export class TipoIdentificacionDTO {
  ICodigo: number;
  SDescripcion: string;
}

export class RequestPrueba {
  id: number;
  sNombre: string;
  lista: string[];
}

export class Medios {
  codigo: number;
  nombre: string;
}

export class Comunicacion {
  ICodigo: number;
  CMensaje: string;
  FFechaComunicacion: Date;
  CEstado: string;
  ITipoComunicacion: TipoComunicacion;
  ITipoMensaje: TipoMensaje;
  ISolCredito: SolicitudCredito
}

export class TipoComunicacion {
  ICodigo: number;
  CDescripcion: string;
}

export class TipoMensaje {
  ICodigo: number;
}

export class Parametro {
  CDescripcion: string;
  CEstado: string;
  CNombre: string;
  CValor: string;
  ICodigo: number;
}

export class RespuestaPermite {
  R_s_respuesta: string;
}

export class Girador {
  ICodigo: number;
  CCorreo: string;
  CuentaGirador: number;
  CDireccion1: string;
  CDireccion2: string;
  CDireccion3: string;
  FFechaExpedicion: Date;
  FFechaNacimientoDate;
  ICiudadDomicilio: CiudadDTO;
  ICiudadExpedicion: CiudadDTO
  IClasePersona: any;
  IDepartamentoDomicilio: any;
  IDepartamentoExpedicion: any;
  IPaisDomicilio: any;
  IPaisExpedicion: any
  IPaisNacionalidad: any;
  ITipoDocumento: number;
  CIdentificacionEmisor: string;
  CNombres: string;
  CNumCelulars: string;
  CNumDocumento: string;
  CPrimerApellidos: string;
  Salario: string;
  CSegundoApellido: string;
  CTelefono1s: string;
  CTelefono2s: string;
  CTelefono3s: string;
  IProcesoPagareDeceval: ProcesoPagare;
}

export class consultaPagareServiceDTO {
  codigoDeceval: number;
  idTipoIdentificacionFirmante: number;
  numIdentificacionFirmante: string;
  idEstadoPagare: number;
}

export class RequestConsultarPagare {
  consultaPagareServiceDTO: consultaPagareServiceDTO;
}

export class ResponseConsultarPagare {
  responseGenerico: ResponseGenerico;
  rtaConsultarPagaresDTO: RespuestaConsultarPagaresDTO;
}

export class ResponseGenerico {
  isSuccess: boolean;
  sMsj: string;
}

export class RespuestaConsultarPagaresDTO {
  codigoError: string;
  descripcion: string;
  exitoso: boolean;
  listaRespuesta: InformacionPagareServiceDTO[];
}

export class InformacionPagareServiceDTO {
  estadoPagare: string;
  fechaFirmaPagare: Date;
  fechaGrabacionPagare: Date;
  idPagareDeceval: number;
  nombreOtorgante: string;
  numPagareEntidad: string;
  numeroDocumentoOtorgante: string;
  pdfPagare: ArchivoAdjuntoDTO;
  tipoDocumentoOtorgante: string;
}

export class ArchivoAdjuntoDTO {
  contenido: string;
  nombreArchivo: string;
}

export class ResponseConsultaNumeroPagare {
  bRta: boolean;
  sMsj: string;
  iNumero: number;
}

export class CreacionPagareDeceval {
  ICodigo: number;
  IClaseDefinicionDocumento: number;
  COtorganteCta: string;
  COtorganteNumDoc: string;
  IOtorganteTipoDoc: number;
  CNitEmisor: string;
  ICiudadDesembolso: CiudadDTO;
  IProcesoPagareDeceval: ProcesoPagare;
  ITipoPagare: TipoPagareDeceval;
}

export class ResponseCrearPagare {
  responseGenerico: ResponseGenerico;
  rtaDocumentoPagareServiceDTO: RtaDocumentoPagareServiceDTO;
}

export class TipoPagareDeceval {
  ICodigo: number;
  CTipoPagareDeceval: string;
}

export class InformacionFirmaPagareDTO {
  CClave: string;
  CDocPagare: string;
  CNumDoc: string;
  ITipoDoc: number;
  IProcesoPagare: ProcesoPagare;
}

export class ResponseFirmarPagare {
  responseGenerico: ResponseGenerico;
  respuestaFirmarPagaresDTO: RespuestaFirmarPagaresDTO;
}

export class RespuestaFirmarPagaresDTO {
  descripcion: string;
}

export class RolPersonaPagareDeceval {
  iCodigo: number;
  cNombreRol: string;
}

export class ProcesoPagare {
  ICodigo: number;
}

export class RtaDocumentoPagareServiceDTO {
  codigoError: string;
  descripcion: string;
  exitoso: string;
  listaRespusta: listaRespusta[];
}
export class listaRespusta {
  ciudadDesembolso: string;
  creditoReembolsableEn: string;
  departamento: string;
  idClaseDefinicionDocumento: number;
  idDocumentoPagare: number;
  mensajeRespuesta: string;
  nitEmisor: string;
  otorganteCuenta: string;
  otorganteNumId: string;
  otorganteTipoId: string;
  pais: number;
  tipoPagare: number;
}

//import { SolicitudCredito } from "./Solicitud-Credito";

/*export class MenuDetalleSolicitud {
  idMenu: number;
  nombre: string;
  ruta: string;
  revisado: boolean;
}*/

export class Cuota {
  numPrima: number;
  valor: number;
}

/*export class RequestLogin {
  tipoDocumento: number;
  tipoPerfil: number;
  numDocumento: string;
  password: string;
  sNavegador: string;
  iTipoCanal: number;
}*/
/*export class TipoDireccion {
  ICodigo: any;
  SDescripcion: any;
  CDescripcion: any;
}*/

/*export class CompDireccion {
  ICodigo: any;
  SDescripcion: any;
  CDescripcion: any;
}*/
/*export class frmDireccion {

    public TipoPrimera: any;
    public Nprincipal: any;
    public SufijoPrimero: any;
    public NSecundario: any;
    public SufijoSegundo: any;
    public NComp: any;
    public ComplementoPrimero: any;
    public NumeroPrimero: any;
    public complementoSegundo: any;
    public NumeroSegundo: any

}*/
/*export class ResponseTipoContrato {
  BRta: boolean;
  SMsjConsulta: string;
  listTipoContr: ArrayOfTipoContratoDTO;
}*/

/*export class ArrayOfTipoContratoDTO {
  TipoContratoDTO: TipoContratoDTO[];
}*/

/*export class TipoContratoDTO {
  ICodigo: number;
  SDescripcion: string;
}*/

/*export class ResponseTiposIdent {
  BRta: boolean;
  SMsjConsulta: string;
  LstTiposIdent: ArrayOfTipoIdentificacionDTO;
}*/

/*export class ArrayOfTipoIdentificacionDTO {
  tipoIdentificacionDTO: TipoIdentificacionDTO[];
}*/

/*export class TipoIdentificacionDTO {
  ICodigo: number;
  SDescripcion: string;
}*/

/*export class RequestPrueba {
  id: number;
  sNombre: string;
  lista: string[];
}*/

/*export class Medios {
  codigo: number;
  nombre: string;
}*/

/*export class Comunicacion {
  ICodigo: number;
  CMensaje: string;
  FFechaComunicacion: Date;
  CEstado: string;
  ITipoComunicacion: TipoComunicacion;
  ITipoMensaje: TipoMensaje;
  ISolCredito: SolicitudCredito
}*/

/*export class TipoComunicacion {
  ICodigo: number;
  CDescripcion: string;
}*/

/*export class TipoMensaje {
  ICodigo: number;
}*/

/*export class Parametro {
  CDescripcion : string;
  CEstado      : string;
  CNombre      : string;
  CValor       : string;
  ICodigo      : number;
}*/

/*export class RespuestaPermite {
  R_s_respuesta  : string;
}*/
