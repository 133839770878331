import { SolicitudCredito } from './../../model/Solicitud-Credito';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Comunicacion, consultaPagareServiceDTO, CreacionPagareDeceval, Girador, InformacionFirmaPagareDTO, RequestConsultarPagare, RequestLogin } from '../../model/Clases';
import {
  DocumentoTransunion,
  RequestCambiarEstadoSolCred,
  RequestCupoMaxSolCred,
  RequestDatosPersonales,
  RequestSimuladorCredito,
  RequestValidarPersona,
  RequestValidCodigo,
  RequestValIdent,
  ResponseAperturaCuentaSolCred,
} from '../../model/Solicitud-Credito';

@Injectable({
  providedIn: 'root',
})
export class SolicitudCreditoService {
  public server = environment.URL_SERVICE;
  public headers = new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: 'Bearer' + sessionStorage.getItem('token'),
  });

  constructor(private http: HttpClient) { }

  iniciarSesionSolCred(requestValidarPersona: RequestValidarPersona) {
    let url = this.server + 'WSSolCredito/validarPersona';
    return this.http.post(url, requestValidarPersona, {
      headers: this.headers,
    });
  }

  validarIdentidad(requestValIdent: RequestValIdent) {
    let url = this.server + 'WSSolCredito/generarCodigoVal';
    return this.http.post(url, requestValIdent, { headers: this.headers });
  }

  validarCodigo(requestValidCodigo: RequestValidCodigo) {
    let url = this.server + 'WSSolCredito/validarCodigoVal';
    return this.http.post(url, requestValidCodigo, { headers: this.headers });
  }

  lineasCreditoHab() {
    let url = this.server + 'WSAdminCore/lineasCreditoHab';
    return this.http.post(url, null, { headers: this.headers });
  }

  cargarTodasSolicitudesCredito() {
    let url = this.server + 'WSSolCredito/buscarTodasSolCredito';
    return this.http.post(url, { headers: this.headers });
  }

  cargarSolicitudByRadicado(iNumRadic: string) {
    let url = this.server + 'WSSolCredito/buscarSolCred?iNumRadic=' + iNumRadic;
    return this.http.post(url, null, { headers: this.headers });
  }

  obtenerDatosPersonales(request: RequestDatosPersonales) {
    let url = this.server + 'WSSolCredito/obtenerDatosPersonales';
    return this.http.post(url, request, {
      headers: this.headers,
    });
  }

  obtenerDocumentosSolCred(idSolCredito: string) {
    let url =
      this.server +
      'WSSolCredito/obtenerDocumentosSolCred?idSolCredito=' +
      idSolCredito;
    return this.http.post(url, null, {
      headers: this.headers,
    });
  }

  saveDocumentoTU(documentoTransunion: DocumentoTransunion) {
    let url = this.server + 'WSSolCredito/saveDocumentoTU';
    return this.http.post(url, documentoTransunion, {
      headers: this.headers,
    });
  }

  obtenerDocumentoTU(idSolCred: string) {
    let url =
      this.server + 'WSSolCredito/obtenerDocumentoTU?idSolCredito=' + idSolCred;
    return this.http.post(url, {
      headers: this.headers,
    });
  }

  obtenerValoresMaximos(requestCupoMaxSolCred: RequestCupoMaxSolCred) {
    let url = this.server + 'WSSolCredito/calcularCuposMaximos';
    return this.http.post(url, requestCupoMaxSolCred, {
      headers: this.headers,
    });
  }

  simuladorCreditoAtr(requestSimuladorCredito: RequestSimuladorCredito) {
    let url = this.server + 'WSSolCredito/simuladorCreditoAtr';
    return this.http.post(url, requestSimuladorCredito, {
      headers: this.headers,
    });
  }

  cambiarEstadoSolicitud(request: RequestCambiarEstadoSolCred) {
    let url = this.server + 'WSSolCredito/cambiarEstadoSolicitud';
    return this.http.post(url, request, {
      headers: this.headers,
    });
  }

  cambiarEstadoSolicitudWLO(request: RequestCambiarEstadoSolCred) {
    let url = this.server + 'WSSolCredito/cambiarEstadoSolicitudWLO';
    return this.http.post(url, request, {
      headers: this.headers,
    });
  }

  obtenerComunicaciones(idSolCred: string) {
    let url = `${this.server}WSSolCredito/obtenerComunicados?idSolCred=${idSolCred}`;
    return this.http.post(url, null, {
      headers: this.headers,
    });
  }

  comunicarAsociado(request: Comunicacion) {
    let url = `${this.server}WSSolCredito/guardarComunicacion`;
    return this.http.post(url, request, {
      headers: this.headers,
    });
  }

  obtenerAperturaCuenta(idSolCred: number): Observable<ResponseAperturaCuentaSolCred> {
    let url =
      this.server + 'WSSolCredito/obtenerAperturaCuenta?idSolCred=' + idSolCred;
    return this.http.post<ResponseAperturaCuentaSolCred>(url, null, {
      headers: this.headers,
    });
  }

  ObtenerDatosDesembolso(iNumRadic: string, CIdentificacion: string) {
    let url = this.server + 'WSSolCredito/consultaSolicitudDesembolso?iCedula=' + CIdentificacion + '&iNumRadicado=' + iNumRadic;
    return this.http.post(url, null, { headers: this.headers });
  }

  solicitudCreditoCygnus(solicitudCredito: SolicitudCredito, sEstadoCredito: string) {
    let url = this.server + 'WSSolCredito/crearCreditoCygnus?sEstadoCredito=' + sEstadoCredito;
    return this.http.post(url, solicitudCredito, {
      headers: this.headers,
    });
  }

  guardarPagare(isolicitudCredito: SolicitudCredito) {
    let url = `${this.server}WSSolCredito/saveDocumentoPagare`;
    return this.http.post(url, isolicitudCredito, {
      headers: this.headers,
    });
  }

  obtenerNavegador() {
    let ua = navigator.userAgent;
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
        ua
      )
    ) {
      return 'iOs';
    } else if (/Chrome/i.test(ua)) {
      return 'Chrome';
    } else {
      return 'Desktop';
    }
  }

  ////ENDPOINT DECEVAL

  crearGirador(datosGirador: Girador) {
    let url = `${this.server}WSSolCredito/creargirardor`;
    return this.http.post(url, datosGirador, {
      headers: this.headers,
    });
  }

  /**
   * Metodo para consultar pagares en Deceval
   * @param consultaPagareServiceDTO
   * @returns
   */
  consultaPagares(requestConsultarPagare: RequestConsultarPagare) {
    let url = `${this.server}WSSolCredito/consultaPagares`;
    return this.http.post(url, requestConsultarPagare, {
      headers: this.headers,
    });
  }

  consultaNumeroPagareDeceval(iSolCred: number) {
    let url = this.server + 'WSDeceval/consultaNumeroPagareDeceval?iSolCred=' + iSolCred;
    return this.http.post(url, null, {
      headers: this.headers,
    });
  }

  consultaNumeroCuentaGirador(iTipoId: number, numId: string) {
    let url = this.server + 'WSDeceval/consultaNumeroCuentaGirador?iTipoId=' + iTipoId + '&numId=' + numId;
    return this.http.post(url, null, {
      headers: this.headers,
    });
  }

  crearPagare(creacionPagareDeceval: CreacionPagareDeceval) {
    let url = `${this.server}WSDeceval/crearPagare`;
    return this.http.post(url, creacionPagareDeceval, {
      headers: this.headers,
    });
  }

  /*import { SolicitudCredito } from './../../model/Solicitud-Credito';
  import { Injectable } from '@angular/core';
  import { environment } from '../../../../environments/environment';
  import { HttpHeaders, HttpClient } from '@angular/common/http';
  import { Observable } from 'rxjs';
  import { Comunicacion, RequestLogin } from '../../model/Clases';
  import {
    DocumentoTransunion,
    RequestCambiarEstadoSolCred,
    RequestCupoMaxSolCred,
    RequestDatosPersonales,
    RequestSimuladorCredito,
    RequestValidarPersona,
    RequestValidCodigo,
    RequestValIdent,
    ResponseAperturaCuentaSolCred,
  } from '../../model/Solicitud-Credito';*/

  /*@Injectable({
    providedIn: 'root',
  })*/
  /*export class SolicitudCreditoService {
    public server = environment.URL_SERVICE;
    public headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer' + sessionStorage.getItem('token'),
    });*/

  //constructor(private http: HttpClient) { }

  /*iniciarSesionSolCred(requestValidarPersona: RequestValidarPersona) {
    let url = this.server + 'WSSolCredito/validarPersona';
    return this.http.post(url, requestValidarPersona, {
      headers: this.headers,
    });
  }*/

  /*validarIdentidad(requestValIdent: RequestValIdent) {
    let url = this.server + 'WSSolCredito/generarCodigoVal';
    return this.http.post(url, requestValIdent, { headers: this.headers });
  }

  validarCodigo(requestValidCodigo: RequestValidCodigo) {
    let url = this.server + 'WSSolCredito/validarCodigoVal';
    return this.http.post(url, requestValidCodigo, { headers: this.headers });
  }

  lineasCreditoHab() {
    let url = this.server + 'WSAdminCore/lineasCreditoHab';
    return this.http.post(url, null, { headers: this.headers });
  }

  cargarTodasSolicitudesCredito() {
    let url = this.server + 'WSSolCredito/buscarTodasSolCredito';
    return this.http.post(url, { headers: this.headers });
  }

  cargarSolicitudByRadicado(iNumRadic: string) {
    let url = this.server + 'WSSolCredito/buscarSolCred?iNumRadic=' + iNumRadic;
    return this.http.post(url, null, { headers: this.headers });
  }

  obtenerDatosPersonales(request: RequestDatosPersonales) {
    let url = this.server + 'WSSolCredito/obtenerDatosPersonales';
    return this.http.post(url, request, {
      headers: this.headers,
    });
  }

  obtenerDocumentosSolCred(idSolCredito: string) {
    let url =
      this.server +
      'WSSolCredito/obtenerDocumentosSolCred?idSolCredito=' +
      idSolCredito;
    return this.http.post(url, null, {
      headers: this.headers,
    });
  }

  saveDocumentoTU(documentoTransunion: DocumentoTransunion) {
    let url = this.server + 'WSSolCredito/saveDocumentoTU';
    return this.http.post(url, documentoTransunion, {
      headers: this.headers,
    });
  }

  obtenerDocumentoTU(idSolCred: string) {
    let url =
      this.server + 'WSSolCredito/obtenerDocumentoTU?idSolCredito=' + idSolCred;
    return this.http.post(url, {
      headers: this.headers,
    });
  }

  obtenerValoresMaximos(requestCupoMaxSolCred: RequestCupoMaxSolCred) {
    let url = this.server + 'WSSolCredito/calcularCuposMaximos';
    return this.http.post(url, requestCupoMaxSolCred, {
      headers: this.headers,
    });
  }

  simuladorCreditoAtr(requestSimuladorCredito: RequestSimuladorCredito) {
    let url = this.server + 'WSSolCredito/simuladorCreditoAtr';
    return this.http.post(url, requestSimuladorCredito, {
      headers: this.headers,
    });
  }

  cambiarEstadoSolicitud(request: RequestCambiarEstadoSolCred) {
    let url = this.server + 'WSSolCredito/cambiarEstadoSolicitud';
    return this.http.post(url, request, {
      headers: this.headers,
    });
  }

  obtenerComunicaciones(idSolCred: string) {
    let url = `${this.server}WSSolCredito/obtenerComunicados?idSolCred=${idSolCred}`;
    return this.http.post(url, null, {
      headers: this.headers,
    });
  }

  comunicarAsociado(request: Comunicacion) {
    let url = `${this.server}WSSolCredito/guardarComunicacion`;
    return this.http.post(url, request, {
      headers: this.headers,
    });
  }

  obtenerAperturaCuenta(idSolCred: number): Observable<ResponseAperturaCuentaSolCred> {
    let url =
      this.server + 'WSSolCredito/obtenerAperturaCuenta?idSolCred=' + idSolCred;
    return this.http.post<ResponseAperturaCuentaSolCred>(url, null, {
      headers: this.headers,
    });
  }

  ObtenerDatosDesembolso(iNumRadic: string, CIdentificacion: string) {
    let url = this.server + 'WSSolCredito/consultaSolicitudDesembolso?iCedula=' + CIdentificacion + '&iNumRadicado=' + iNumRadic;
    return this.http.post(url, null, { headers: this.headers });
  }

  solicitudCreditoCygnus(solicitudCredito: SolicitudCredito, sEstadoCredito: string) {
    let url = this.server + 'WSSolCredito/crearCreditoCygnus?sEstadoCredito=' + sEstadoCredito;
    return this.http.post(url, solicitudCredito, {
      headers: this.headers,
    });
  }

  guardarPagare(isolicitudCredito: SolicitudCredito) {
    let url = `${this.server}WSSolCredito/saveDocumentoPagare`;
    return this.http.post(url, isolicitudCredito, {
      headers: this.headers,
    });
  }

  obtenerNavegador() {
    let ua = navigator.userAgent;
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
        ua
      )
    ) {
      return 'iOs';
    } else if (/Chrome/i.test(ua)) {
      return 'Chrome';
    } else {
      return 'Desktop';
    }
  }*/
}
